import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'serviceLineVisitTimesWithQuantityPipe',
  pure: true,
})
/*
Transforms the visit start time, selected visit days, and quantity into a human-readable string.
Output example: "4 Termine: montags und donnerstags um 14:00 Uhr"
Usage:   {{ serviceLine.visitStartTime | serviceLineVisitTimesWithQuantityPipe: serviceLine.selectedVisitDays : serviceLine.quantity }}
*/
export class ServiceLineVisitTimesWithQuantityPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  private weekdayKeys: { [key: string]: string } = {
    sunday: 'weekdayEntity.days.sunday',
    monday: 'weekdayEntity.days.monday',
    tuesday: 'weekdayEntity.days.tuesday',
    wednesday: 'weekdayEntity.days.wednesday',
    thursday: 'weekdayEntity.days.thursday',
    friday: 'weekdayEntity.days.friday',
    saturday: 'weekdayEntity.days.saturday',
  };

  transform(visitStartTime: string, selectedVisitDays: string[], quantity: number): string {
    if (!visitStartTime || !selectedVisitDays || selectedVisitDays.length === 0 || quantity <= 0) {
      return '';
    }

    const translatedDays = selectedVisitDays
      .map(day => this.translate.instant(this.weekdayKeys[day.toLocaleLowerCase()] || day.toLowerCase()))
      .join(', ');

    return this.translate.instant('serviceOrder.create.visitTimes_quantity_placeholder', {
      quantity: quantity,
      days: translatedDays,
      time: visitStartTime,
    });
  }
}
