import { NgModule } from '@angular/core';

import { ServiceLocationPipe } from './service-location-pipe';
import { ServiceLineSummaryInfoTextPipe } from './serviceLine-SummaryInfoText-pipe';
import { ServiceLineVisitTimesPipe } from './serviceLine-visitTime-pipe';
import { ServiceLineVisitTimesWithQuantityPipe } from './serviceLine-visitTimeWithQuantity-pipe';

@NgModule({
  declarations: [
    ServiceLocationPipe,
    ServiceLineVisitTimesPipe,
    ServiceLineVisitTimesWithQuantityPipe,
    ServiceLineSummaryInfoTextPipe,
  ],
  exports: [
    ServiceLocationPipe,
    ServiceLineVisitTimesPipe,
    ServiceLineVisitTimesWithQuantityPipe,
    ServiceLineSummaryInfoTextPipe,
  ],
})
export class ServicePipesModule {}
