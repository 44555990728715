import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'serviceLineSummaryInfoTextPipe',
  pure: true,
})
/**
 * Transforms a start date, end date, and quantity into a human-readable string.
 * Output example: "Für den Zeitraum 17.12.2024 bis 31.12.2024 werden <b>6 Termine</b> entsprechend der Besuchszeiten erstellt."
 * Usage:   {{ startDate | dateRangeWithQuantityPipe:endDate:quantity }}
 */
export class ServiceLineSummaryInfoTextPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(startDate: Date, endDate: Date, quantity: number): string {
    if (!startDate || !endDate || quantity <= 0) {
      return '';
    }

    // Format dates to desired output format (e.g., DD.MM.YYYY)
    const formattedStartDate = startDate.toLocaleDateString('de-DE');
    const formattedEndDate = endDate.toLocaleDateString('de-DE');

    // Wrap the quantity and Termine in bold tags
    const boldQuantity = `<b>${quantity} Termine</b>`;

    return this.translate.instant('serviceOrder.create.dateRange_quantity_placeholder', {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      quantity: boldQuantity,
    });
  }
}
