import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'itl-card-2',
  template: `<div class="card" [ngClass]="{ selected }"><ng-content></ng-content></div>`,
  styles: [
    `
      .card {
        display: block;
        border-radius: 15px;
        border: 1px solid rgba(43, 43, 43, 0.12);
        box-shadow: 0px 4px 6px rgba(43, 43, 43, 0.12);
        margin: var(--card2-margin, 0);
        max-width: var(--card2-max-width, 100%);
        background-color: white;
        overflow: hidden;
      }
      .selected {
        border: 2px solid rgba(38, 128, 235, 0.5);
        box-shadow: 0px 1px 10px rgba(38, 128, 235, 0.5);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class Card2Component {
  @Input() public selected: boolean;
}
