import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'serviceLineVisitTimesPipe',
  pure: true,
})
/*
Transforms the visit start time and selected visit days into a human readable string.
Output "Besuchszeiten: montags und samstags um 12:06 Uhr"
Usage:   {{ serviceLine.visitStartTime | serviceLineVisitTimesPipe: serviceLine.selectedVisitDays }}
*/
export class ServiceLineVisitTimesPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  private weekdayKeys: { [key: string]: string } = {
    sunday: 'weekdayEntity.days.sunday',
    monday: 'weekdayEntity.days.monday',
    tuesday: 'weekdayEntity.days.tuesday',
    wednesday: 'weekdayEntity.days.wednesday',
    thursday: 'weekdayEntity.days.thursday',
    friday: 'weekdayEntity.days.friday',
    saturday: 'weekdayEntity.days.saturday',
  };

  transform(visitStartTime: string, selectedVisitDays: string[]): string {
    if (!visitStartTime || !selectedVisitDays || selectedVisitDays.length === 0) {
      return '';
    }
    const translatedDays = selectedVisitDays
      .map(day => this.translate.instant(this.weekdayKeys[day.toLowerCase()]) || day.toLowerCase)
      .join(', ');

    return this.translate.instant('serviceEntity.visitTimesPlaceholder', {
      days: translatedDays,
      time: visitStartTime,
    });
  }
}
